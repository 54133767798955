import eventBus from "@/event-bus";
import { teclasFuncion } from "@/utilities/global-constants";

const consola = function () {
	void 0;
};
let contador = 0;
var lectura_generica = "";
let bindLectorGenerico;

/** Listener, tiene que estar con la función para poder escupir datos a la variable compartida  */
const scanListenerArticulos = function (elId, e) {
	if (e.keyCode == 13) {
		e.preventDefault();
		e.stopPropagation();
		window.fake_console(lectura_generica);
		// Ejemplo:
		// original: CV_065_00_1021501_5278_20230914
		// SAP: ZCV_0065_A000_1021501_0065.5278_20230914

		// lectura_generica = "ZCV_0065_A000_1021501_0065.5278_20230914";

		// Si el código empieza por Z es que viene de SAP y debemos normalizarlo
		if (lectura_generica.startsWith("Z")) {
			let partes = lectura_generica.split("_");
			if (partes.length >= 5) {
				// Quitar la "Z" inicial de la primera parte (ZCV -> CV)
				if (partes[0].startsWith("Z")) {
					partes[0] = partes[0].substring(1);
				}
				// La parte del PDV: de 4 dígitos a 3 dígitos (ej: "0065" -> "065")
				if (partes[1] && partes[1].length === 4) {
					partes[1] = partes[1].substring(1);
				}
				// La tercera parte: de formato "A0**" a "**" (ej: "A000" -> "00")
				if (partes[2] && partes[2].startsWith("A0")) {
					partes[2] = partes[2].substring(2);
				}
				// En la penúltima parte (ej: "0065.5278" -> "5278") 
				let indicePenultima = partes.length - 2;
				if (partes[indicePenultima] && partes[indicePenultima].includes(".")) {
					let subPartes = partes[indicePenultima].split(".");
					if (subPartes.length > 1) {
						partes[indicePenultima] = subPartes[1];
					}
				}
				lectura_generica = partes.join("_");
			}
		}

		eventBus().emitter.emit(`lectura_generica:${elId}`, lectura_generica);
		lectura_generica = "";
	} else {
		/*lectura_generica += e.keyCode == 32 ? ' ' : e.keyCode != 16 ? e.key : '';*/
		lectura_generica +=
			e.keyCode == 32
				? " "
				: teclasFuncion.indexOf(e.keyCode) === -1
				? e.key
				: "";
	}
};

/** FUNCION DE ESCANEADO  */
const scanGenericStart = async function (elId, comentario) {
	consola(comentario);
	lectura_generica = "";
	consola(elId);
	bindLectorGenerico = scanListenerArticulos.bind(null, elId);
	consola("31: ", elId, lectura_generica);
	document.addEventListener(`keydown`, bindLectorGenerico);
	contador += 1;
	consola("CONTADOR SUBE : " + contador);
	eventBus().emitter.on(
		`lectura_generica:${elId}`,
		async function (art_from_keyboard) {
			consola(
				`Articulo escaneada: verificamos lectura lectura_generica:${elId} | ${art_from_keyboard}`
			);
			lectura_generica = "";
		}
	);
};

const scanGenericStop = async function (elId) {
	lectura_generica = "";
	try {
		document.removeEventListener(`keydown`, bindLectorGenerico);
		eventBus().emitter.off(`lectura_generica:${elId}`);
		contador -= 1;
		consola("Saliendo, contador baja : " + contador);
		return true;
	} catch (ex) {
		consola("Tengo un error ", ex);
		return false;
	}
};

export { scanGenericStart, scanGenericStop };